<!-- 提现充值还款成功 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20 bg-fff flex-column-center-center" :class="fSettleModeID == 8?'':'success-box'">
				<div v-if='fSettleModeID == 8'>
					<div style="margin-top: 42px;">
						<div style="display: flex;justify-content: center;">
							<div>
								<div class="circleClass" style="background-color: #0173FE;color: white;">1</div>
								<div class="ecContent" style="color:#0173FE">申请</div>
							</div>
							
							<div class="lineClass"></div>
							<div>
								<div class="circleClass" style="background-color: #0173FE;color: white;">2</div>
								<div class="ecContent" style="color:#0173FE">待审核</div>
							</div>
							<div class="lineClass"></div>
							<div>
								<div class="circleClass">3</div>
								<div class="ecContent">审核中</div>
							</div>
							<div class="lineClass"></div>
							<div>
								<div class="circleClass">4</div>
								<div class="ecContent">结束</div>
							</div>
							
						</div>
					</div>
					<div class="secondDiv">
						<div class="stepDiv">
							<div>第一步：企业用户请下载并上传加盖公章的退款申请及加盖财务专用章的收款收据；个人用户请下载并上传退款申请、收条及身份证复印件（以上材料均需本人签字及按手印）。</div>
							<div style="margin-top: 30px;">第二步：请在3日内邮寄纸质版加盖公章的退款申请及加盖财务专用章的收款收据至聚材公司。</div>
						</div>
						<div style="margin-left: 49px;">
							<div class="newBtn" @click="openUploaders">下载/上传退款申请</div>
						</div>
					</div>
					<div class="threeDiv">
						<div style="addressDiv">
							<div class='addressDivChinese'>资料邮寄地址：新疆乌鲁木齐市新市区河北东路1256号天合大厦326室</div>
							<div class='addressDivChinese'>收件人：聚材公司</div>
							<div class='addressDivChinese'>电话：400-666-3439</div>
						</div>
						<div>
							<div class="newBtn" @click='copyMessage'>复制邮寄信息</div>
						</div>
					</div>
					<div style="margin-top: 15px;">我们将在收取到您邮寄的纸质版材料后，尽快为您办理退款</div>
					<div  style="margin-top: 60px;width: 100%;display: flex;justify-content: center;">
						<div class="newBtnBill" style="cursor: pointer;" @click="PaymentSuccessfull">返回钱包</div>
					</div>
				</div>
				<div v-else>
					<div class="flex-row-center-center">
						<imageBox :width='176' :height='143' :src="url"></imageBox>
					</div>
					<div v-if="examine==254" class=" text-center font-size16 font-weight700 color-theme">
						{{successTypeName}}审核已通过，退款中...
					</div>
					<div v-else class=" text-center font-size16 font-weight700 color-theme margin-b-10">
						{{successTypeName}}申请已提交，正在审核，请您耐心等待 ~~
					</div>
					<div v-if="(fSettleModeID == 8) ||(fSettleModeID == 13)">
						<div  @click="openUploaders" style="background-color: #0173FE;color: #fff; padding: 0px 6px;font-size: 10px;cursor: pointer;height: 36px; line-height: 36px;margin-top: 5px;margin-bottom: 5px;" class="font-size14">下载/上传退款申请</div>
					</div>
					<div v-if="billNumber!=''&&billNumber!=null" class=" text-center font-size13 font-color-999 margin-b-2">
						单据号：{{billNumber}}
					</div>
					<div class="text-center font-size13 font-color-999">
						谢谢您对聚材通平台的支持！
					</div>
					<div class="background-color-theme success-button flex-row-center-center margin-t-20 font-size14" @click="PaymentSuccessfull">
						返回钱包
					</div>
				</div>
			<!--上传文件的弹框-->
			<el-dialog v-if='showUploaderModel' title="下载/上传退款申请" top="5vh" :close-on-click-modal="false" :visible.sync="showUploaderModel"  width="30%" :before-close="updhandleClose">
				
				<div ref="dialogBox" class="dialogScroll">
					<el-form ref="form"  :model="uploadforms" :label-width="formLabelWidth" label-position="left">
						<el-form-item label="提现单号" prop="fRefundBillNumber" style="position: relative;">
							<el-input v-model="uploadforms.fRefundBillNumber" disabled></el-input>
						</el-form-item>
						<el-form-item label="提现金额" prop="fRefundGoodsMoney" style="position: relative;">
							<el-input v-model="uploadforms.fRefundGoodsMoney" disabled></el-input>
						</el-form-item>
						<div v-for="(item, index) in imgObj" :key="index">
							<el-form-item :label="item.rule.fRuleName"  :class="item.rule.fIsNecessary==0 ? '': 'isNecessary'" prop="fRefundGoodsMoney" style="position: relative;height: 210px;overflow: hidden;">
								<div class="flex-row">
										<div class="form-box-in">
											<div style="width: 150px;height: 150px;overflow: hidden;">
											 <el-upload
											   :action="actionback"
											   list-type="picture-card"
											   :on-remove="handleRemove"
											   :on-success="handlesuceess"
											   :before-upload='beforeupload'
											   :data="uploadToken[index]"
											   :multiple="false" 
											   :limit="1"
											   :accept="item.rule.fFileType"
											   :disabled="isshow"
											   >
											   <i class="el-icon-plus" @click="handlebeforeupload(item.rule)"></i>
											 </el-upload>
											 </div>
										<div data-v-4d9d131f="" class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
									</div>
								</div>
							</el-form-item>
						</div>
						<div data-v-4d9d131f="" class="font-color-FF9900 font-size12 margin-b-10">请将退款申请函及收据原件寄至:{{addressshow}} 电话:{{phoneshow}}</div>
						<el-form-item label="" style="text-align: right;">
							  <div @click="getonDownload" style="padding: 0px 6px ; border: 1px #eee solid;text-align: center;display: inline-block;font-size: 12px;height: 32px;line-height: 32px;border-radius: 5px;background-color: #409EFF;color: #fff;cursor: pointer;">下载申请函模板</div>
						</el-form-item>
						
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="updhandleClose">取 消</el-button>
					<el-button type="primary" @click="subforms">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import imageBox from '@/components/imageBox/imageBox.vue'
	import uploadFile from "@/utils/uploadFile";
	export default {
		components: {
			imageBox
		},
		data() {
			return {
				fIsNecessaryData: [],//必传数据
				isshow:false,
				successType: '', //成功类型 充值:CZ 提现:TX 还款:HK 
				successTypeName: '', //成功类型名字
				examine: null, //  1表示审核中 0表示初始化 254 表示审核通过
				billNumber: null, //单据编号
				type: '', //单据类型 现金钱包:XJ 账服通钱包:ZFT 票据钱包:SX 
				url: require('@/assets/imgs/pay/u5.png'),
				filesetID:"",
				// 表单绑定数据
				uploadforms:{
					fRefundGoodsMoney:"", //提现金额
					fRefundBillNumber:"",// 提现编号
					
				},
				formLabelWidth: '100px',
				imgObj: [], // 图片规则
				list: [], //编辑回传的图片
				uploadToken:[],
				imglistback: [], //上传整理后的图片数组
				editlist: [], //编辑时的图片列表
				fAnnexTypeIDData: [],
				//弹框数据
				showUploaderModel:false,
				fSettleModeID:"",
				actionback:"",
				annexServerUrl: '',
				
				dialogImageUrl: '',
				uploadDir:"Web/SupplyDemand",
				uploadFileAshx:"",
				// 上传数据
				
				ruledom:{}, //每次需要上传组织的数据的rule
				
				
				accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
				
				
				addressshow:"",
				phoneshow:"",
			};
		},
		
		mounted() {
			this.getImgUrl();
			// let data = JSON.parse(decodeURIComponent(this.$route.query.data));
			let data = JSON.parse(this.$route.query.data);
			console.log(data,"前一个页面传来的数据")
			this.filesetID = data.reID
			this.fSettleModeID = data.fSettleModeID
			this.successType = data.successType;
			this.examine = data.examine;
			this.type = data.type;
			console.log(this.examine);
			if (this.successType == 'CZ') {
				this.successTypeName = '充值'
			} else if (this.successType == 'TX') {
				this.successTypeName = '提现'
			} else if (this.successType == 'HK') {
				this.successTypeName = '还款'
			}
		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			copyMessage(){
				const copyText = async (text) => await navigator.clipboard.writeText(text)
				copyText('资料邮寄地址：新疆乌鲁木齐市新市区河北东路1256号天合大厦326室 收件人：聚材公司  电话：400-666-3439')
				 this.$message({
				          message: '信息已成功复制！',
				          type: 'success'
				})
			},
			getaddress(){
				this.ApiRequestPostNOMess(
					"/api/mall/ebshop/baseinfo/get-self-support-shop"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.addressshow = result.obj.fAddress
						this.phoneshow = result.obj.fcsPhone
					},
					(rej) => {}
				);
			},
			handlebeforeupload(e){
				if(this.isshow == true){
					return
				}
				this.ruledom={
					fAnnexTypeID: e.fAnnexTypeID,
					fBillTypeID: e.fBillTypeID,
					fRuleID: e.fRuleID,
					frv: e.frv
				}
			},
			getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.annexServerUrl = result.obj.annexServerUrl
						this.uploadFileAshx = result.obj.annexServerUrl + 'upload';
						
						this.uploadActionURL()
					},
					(rej) => {}
				);
			},
			uploadActionURL() {
				this.actionback =  uploadFile.makeUploadActionURL(this.uploadDir, {
					ashx: this.uploadFileAshx,
				});
				console.log("返回的连接",this.actionback)
			},
			handleRemove(file, fileList) {
			        console.log(file, fileList);
					console.log("删除图片数据",file)
					console.log("删除之前的数据",this.imglistback)
					for(var i =0; i< this.imglistback.length;i++){
						if(this.imglistback[i].uid == file.uid){
							this.imglistback.splice(i,1)
						}
					}
					console.log("删除完的数据",this.imglistback)
			      },
			// 弹框关闭
			updhandleClose(){
				this.showUploaderModel = false
			},
			// 打开弹框
			openUploaders(){
				
				this.getaddress()
				let params ={
					id:this.filesetID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get',params).then(
					result => {
						console.log(result ,"获取接口2 数据")
						// this.uploadforms.name = result.
						this.uploadforms.fRefundGoodsMoney = result.obj.fRefundGoodsMoney //提现金额
						this.uploadforms.fRefundBillNumber = result.obj.fRefundBillNumber // 提现编号
						this.filesetID = this.filesetID
						this.getuploaderrules()
					},
					rej => {}
				);
								this.imglistback = []
				this.showUploaderModel = true
			},
			beforeupload(){
						this.isshow = true
			},
			// 文件上传成功的
			handlesuceess(response, file, fileList){
				
				console.log("上传成功的回调函数response",response)
				console.log("上传成功的回调函数file",file)
				console.log("上传成功的回调函数fileList",fileList)
				console.log("现在的ruleID",this.ruledom)
				this.isshow = false
				this.ruledom.fPath = response.fileName
				this.ruledom.fSourceFileName = file.name
				this.ruledom.fFileExName = file.name.split('.')[0]
				this.ruledom.uid = file.uid
				this.imglistback.push(this.ruledom)
			},
			// 确定
			subforms(){
				// if(this.imglistback.length == 0){
				// 	this.$message.error("请上传图片")
				// }
				if (this.fIsNecessaryData.length > 0) {
					var falg = true
					this.fIsNecessaryData.forEach(item => {
						if (!this.imglistback[item.index]) {
							falg = false
						}
					})
					if(falg == false){
						this.$message.error("请上传图片")
						return
					}
				}
				let arr = []
				for(let item of this.imglistback){
					arr.push({
						fRuleID:item.fRuleID,
						fAnnexTypeID:item.fAnnexTypeID,
						fPath:item.fPath,
						fSourceFileName:item.fSourceFileName,
						fFileExName:item.fFileExName,
					})
				}
				let params = {
					 fRefundBillID: this.filesetID,
					 annexList: arr
				}
				console.log(params)
				this.ApiRequestPost('api/mall/ebsale/refund-bill/create-annex-offline-refund-bill',params).then(
					result => {
						console.log(result ,"获取接口5 数据")
						if(result.code =  "HengHeCode1000"){
							this.showUploaderModel = false
							this.$message.success("上传成功")
							this.uploadforms.fRefundGoodsMoney = ""
							this.uploadforms.fRefundBillNumber = ""
							this.imglistback = []
							this.PaymentSuccessfull()
						}
					},
					rej => {}
				);
			},
			// api/mall/ebsale/refund-bill/print-refund-letter
			//获取图片上传规则
			getuploaderrules(){
				let params = {
					fRefundBillID: this.filesetID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get-annexrule-offline-refund-bill',params).then(
					result => {
						
						console.log(result, '获取图片上传规则111');
						console.log(result.obj)
						this.imgObj = result.obj
						this.imgObj.forEach((item, index) => {
							this.uploadToken.push({token:item.token})
							this.fAnnexTypeIDData.push(item.rule)
						})
						this.fIsNecessaryData = []
						for (let i = 0; i < this.imgObj.length; i++) {
							if (this.imgObj[i].rule.fIsNecessary == 1) {
								this.fIsNecessaryData.push({
									fRuleID: this.imgObj[i].rule.fRuleID,
									fRuleName: this.imgObj[i].rule.fRuleName,
									index: i
								})
							}
						}
						console.log('获取图片上传规则222')
					},
					rej => {}
				);
			},
			 // 模板下载
			getonDownload(){
				let params = {
					fRefundBillID:this.filesetID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/print-refund-letter',params).then(
					result => {
						// console.log("下载元素",result.obj)
						window.open(result.obj)
					},
					rej => {}
				);
			},
			//完成
			PaymentSuccessfull() {
				if (this.type == 'XJ') {
					this.$router.replace({
						path: '/businessme/MoneyDash',
					});
				} else if (this.type == 'PJ') {
					this.$router.replace({
						path: '/businessme/billWallet',
					});
				} else if (this.type == 'ZFT') {
					this.$router.replace({
						path: '/businessme/AccountPay',
					});
				} else if (this.type == 'JYT') {
					this.$router.replace({
						path: '/businessme/JYTwallet',
					});
				}else {
					this.$router.go(-2);
				}
			}
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.success-box {
		width: 897px;
		height: 367px;
	}

	.success-button {
		width: 235px;
		height: 45px;
	}
	/deep/ .el-upload--picture-card{
		position:relative !important;
	}
	.el-upload--picture-card i{
		font-size: 28px;
		    color: #8c939d;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    line-height: 136px;
	}
	.isNecessary:before {
		content: "*";
		position: absolute;
		color: red;
		top: 0px;
		left: 80px;
		font-size: 16px;
		font-weight: 700;
	}
	.stepClass{
		font-size: 18px;
		color: #298ae3;
		letter-spacing: 2px;
		margin-bottom: 20px;
		font-weight: 600;
	}
	.upLoadimg{
		width: 140px;
		line-height: 40px;
		color: white;
		text-align: center;
		background-color: #0173FE;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.postAddress{
		font-size: 14px;
		letter-spacing: 2px;
		color:#298ae3;
		margin-bottom: 20px;
		font-weight: 600;
	}
	.copyMessage{
		width: 100px;
		line-height: 20px;
		color:white;
		background-color: #5A7A97;
		text-align: center;
		border-radius: 5px;
		font-size: 14px;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.circleClass{
		width:70px;
		height: 70px;
		line-height: 70px;
		text-align: center;
		border-radius: 35px;
		letter-spacing: 2px;
		background: #f2f2f2;
		font-weight: bold;
		color: #cccccc;
		font-size: 18px;
	}
	.lineClass{
		width: 80px;
		height: 2px;
		background-color:#5a7a97 ;
		margin-top: 34px;
	}
	.ecContent{
		text-align: center;
		height: 19px;
		font-weight: bold;
		color: #999999;
		margin-top: 10px;
	}
	.secondDiv{
		display: flex;
		background: #ecf5ff;
		padding: 16px;
		margin-top: 20px;
	}
	.stepDiv{
		width: 674px;
		height: 112px;
		font-weight: normal;
		line-height: 28px;
	}
	.newBtn{
		width: 140px;
		height: 40px;
		line-height: 40px;
		background: #ecf5ff;
		font-weight: normal;
		color: #0173fe;
		border: 1px  solid #0173fe;
		text-align: center;
		border-radius: 8px;
	}
	.threeDiv{
		display: flex;
		border: 1px solid #9bb4e6;
		padding: 16px;
		margin-top: 10px;
		justify-content: space-between;
	}
	.addressDiv{
		width: 425px;
		height: 84px;
	}
	.addressDivChinese{
		background: #ffffff;
		font-weight: normal;
		color: #3366cc !important;
		line-height: 28px;
	}
	.newBtnBill{
		width: 206px;
		height: 40px;
		background: #0173fe;
		font-size: 15px;
		font-weight: normal;
		color: #ffffff;
		text-align: center;
		line-height:40px ;
		border-radius: 5px;
	}
</style>
